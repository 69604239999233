export const valueValidate = (role, value) => {
  if (!value.value) {
    return Promise.reject(role.message)
  }
  return Promise.resolve()
}

export const listValidate = (role, value, callback) => {
}

export const typeAssignment = (val) => {
  return val ? val.value : null
}

export const phoneValidate = /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/ // 座机号+手机号

export const mobileValidate = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/ // 手机号

export const idNumberValidate = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

export const moneyValidate = /^(([1-9][0-9]{0,14})|([0]{1})|(([0]\.\d{1,2}|[1-9][0-9]{0,14}\.\d{1,2})))$/

export const positiveNumberValidate = /^[0-9]*$/

export const numberValidate = /^[1-9]*$/

export const number = /^([1-9]\d*)(\.\d{1,6})?$|^0\.\d{1,6}?$/

// 企业信用代码
export const enterpriseValidate = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g

// 银行卡号
export const bankAccount = /^([1-9]{1})(\d{18}|\d{19}|\d{20}|\d{21}|\d{22}|\d{23})$/

// 只能输入数字
export const NumberContent = /^[1-9]\d*$/
// 只能数字或者字母
export const  numberStr =  /^[a-zA-Z0-9]+$/
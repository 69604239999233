<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <ly-button @click="newAdd" class="m-l1" type="primary" style="background: #396AFC;border-color:#396AFC" :loading="false">
          <span style="margin-right: 3px;">+</span>新增账号
        </ly-button>
      </template>
    </ly-searchList>
    <div class="m-t1 tableBox">
      <ly-table :columns="columns" rowKey="id" ref="tableRef" size="small" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
        <template #sort="{index}">
          {{ index+1 }}
        </template>
        <template #status="{ record }">
          <a-popconfirm :title="tipsTitle" ok-text="确定" cancel-text="取消" @confirm="confirm(record)" @cancel="cancel">
            <a-switch size="small" :checkedValue="1" :unCheckedValue="0" @change="swChange($event,record)" :checked="record.isEnable" />
          </a-popconfirm>
        </template>
        <template #add="{ record }">
          <a @click="role(record)">角色</a>
          <a class="m-l1" @click="auth(record)">权限</a>
          <a class="m-l1" @click="edit(record)">编辑</a>
          <span class="m-l1 color-R cur-p" v-if="record.isQuit?.value === 0" @click="delAdd(record)">离职</span>
          <a class="m-l1" v-else @click="delAdd(record)">在职</a>
        </template>
      </ly-table>
    </div>
    <ly-modal v-model:visible="visible" :title="title" :footer="false">
      <ly-formList v-if="visible" :fromList="fromList" ref="formListRef" @cancel="cancel" @getVal="getVal"></ly-formList>
    </ly-modal>
    <ly-modal v-model:visible="delVisible" title="系统提示" delText="确定" @handleOk="handleOk" :isTips="true" :loading="delLoading">
      <p v-if="isQuit===0">确认要把该员工设为离职吗？</p>
      <p v-else>确认要把该员工设为在职吗？</p>
    </ly-modal>
    <!-- 权限模块组件 -->
    <DataAuth ref="DataAuth1" />
    <!-- 角色模块组件 -->
    <DataRole ref="DataRole1" />

  </div>
</template>
  
  <script setup>
import { onMounted, ref } from 'vue'
import { PlusOutlined } from "@ant-design/icons-vue";
import { iptData, columns, fromList } from './employeeFun.js'
import { page, positiontype, orgList, employeeAdd, isEnable, employeeUserId, employeeEdit, employeeQuit } from '@/api/system/employee'
import { transitionTimeYMD, IfNull } from '@/utils/util'
import DataAuth from './dataAuth'
import DataRole from './dataRole'
import { message } from 'ant-design-vue';

import moment from 'moment';
const formListRef = ref(null)
const tipsTitle = ref('')
const visible = ref(false)
const delVisible = ref(false)
const editID = ref({})
const DataRole1 = ref(null)
const DataAuth1 = ref(null)
const isQuit = ref(0)
const loading = ref(false)
const data = ref([])
const searchMsg = ref({})
const title = ref('')
const isEdit = ref(false)
const delLoading = ref(false)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})

const auth = (item) => {
  DataAuth1.value.onClose(true)
  DataAuth1.value.loadData(item.id)
}
const role = (item) => {
  DataRole1.value.onClose(true)
  DataRole1.value.loadData(item.id)
}
const pageChange = (e) => {
  pagination.value = e
  reqPage()
}
//重置
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqPage()
}
const swChange = (e, v) => {
  if (v.isEnable === 0) {
    tipsTitle.value = '确定要打开吗？'
  } else {
    tipsTitle.value = '确定要关闭吗？'
  }
}
const confirm = (v) => {
  isEnable(v.id).then(res => {
    if (res.code !== 10000) return
    reqPage()
    message.success('操作成功')
  })
}
//删除事件
const handleOk = () => {
  delLoading.value = true 
  employeeQuit(editID.value.id).then(res => {
    if (res.code !== 10000) return
    message.success('操作成功')
    delVisible.value = false;
    reqPage()
  }).finally(() => {
    delLoading.value = false
  })
}
const delAdd = (item) => {
  isQuit.value = item.isQuit?.value
  editID.value = item
  delVisible.value = true
}

// 编辑按钮
const edit = (val) => {
  employeeUserId(val.id).then(res => {
    if (res.code !== 10000) return
    editID.value = res.data
    isEdit.value = true
    let item = res.data
    fromList.value.forEach(opt => {
      if (typeof (item[opt.prop]) === 'object') {
        opt.value = item[opt.prop]?.value
      } else {
        opt.value = item[opt.prop]
      }
      if (opt.type === 'selectTime') {
        opt.value = item[opt.prop] ? moment(item[opt.prop]) : null
      }
      if (opt.name === 'no') {
        opt.disabled = true
      }
    })
    title.value = '编辑'
    visible.value = true;
  })
}
// 搜索事件
const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = e
  reqPage()
}
//添加机构
const newAdd = () => {
  fromList.value.forEach(item => {
    item.value = null
    if (item.disabled) {
      item.disabled = false
    }
  })
  isEdit.value = false
  title.value = '新增账号'
  visible.value = true;
}
//获取表单的值
const getVal = (e) => {
  formListRef.value.fileLoading = true
  let smg = JSON.parse(JSON.stringify(e))
  smg.entryDate = transitionTimeYMD(smg.entryDate)
  if (isEdit.value) {
    smg.id = editID.value.id
    employeeEdit(smg).then(res => {
      if (res.code !== 10000) return
      reqPage()
      cancel()
      message.success('修改成功')
    }).finally(() => {
      if (formListRef.value) {
        formListRef.value.fileLoading = false
      }
    })
  } else {
    employeeAdd(smg).then(res => {
      if (res.code !== 10000) return
      reqPage()
      cancel()
      message.success('添加成功')
    }).finally(() => {
      if (formListRef.value) {
        formListRef.value.fileLoading = false
      }
    })
  }

}
const cancel = () => {
  visible.value = false;
}
//所属机构列表
const reqorgList = () => {
  orgList({}).then(res => {
    if (res.code !== 10000) return
    res.data.forEach(item => {
      item.value = item.id
      item.label = item.name
    })
    fromList.value.forEach(item => {
      if (item.label === '所属机构') {
        item.opt = res.data
      }
    })
  })
}
//职务列表
const reqPositiontype = () => {
  positiontype().then(res => {
    if (res.code !== 10000) return
    fromList.value.forEach(item => {
      if (item.label === '职务') {
        item.opt = res.data
      }
    })
  })
}
const reqPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  page(msg).then(res => {
    console.log(res);
    data.value = res.data.records
    pagination.value.total = res.data.total;
  }).finally(() => {
    loading.value = false;
  });
}
onMounted(() => {
  reqPage()
  reqPositiontype()
  reqorgList()
})
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  height: calc(100vh - 230px);
}
</style>
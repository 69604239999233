import { ref } from 'vue'
import { mobileValidate, phoneValidate, moneyValidate, idNumberValidate, positiveNumberValidate, numberStr } from '@/utils/validate'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '姓名',
    value: '',
    prop: 'name',
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '电话',
    value: '',
    prop: 'mobile',
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '账号',
    value: '',
    prop: 'no',
    width: '10%'
  },
  {
    type: 'select',
    placeholder: '岗位状态',
    value: null,
    prop: 'isQuit',
    width: '10%',
    opt:[
      {
        label: '在职',
        value: 0
      },
      {
        label: '离职',
        value: 1
      }
    ]
  },
])
export const columns =ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots: { customRender: 'sort' },
    align: 'center',
    width: '5%'
  },
  {
    title: '姓名',
    dataIndex: 'name',
    align: 'center'
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    align: 'center'
  },
  {
    title: '身份证号',
    dataIndex: 'idNumber',
    align: 'center'
  },
  {
    title: '账号',
    dataIndex: 'no',
    align: 'center'
  },
  {
    title: '所属机构',
    dataIndex: 'orgName',
    align: 'center'
  },
  {
    title: '职务',
    dataIndex: 'positionType.label',
    align: 'center'
  },
  {
    title: '入职时间',
    dataIndex: 'entryDate',
    align: 'center'
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '岗位状态',
    dataIndex: 'isQuit.label',
    align: 'center'
  },
  {
    title: '账号状态',
    dataIndex: 'isEnable',
    align: 'center',
    slots:{
      customRender: 'status'
    }
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: { customRender: 'add' },
    width: '11%'
  },

])
export const fromList = ref([
  {
    label: '姓名',
    name: 'name',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入姓名',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '电话',
    name: 'mobile',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'mobile',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入电话',
        trigger: 'blur'
      },
      {
        pattern:phoneValidate,
        message: '电话格式不正确',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '身份证号码',
    name: 'idNumber',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'idNumber',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入身份证号码',
        trigger: 'blur'
      },
      {
        pattern:idNumberValidate,
        message: '身份证号码格式不正确',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '账号',
    name: 'no',
    type: 'input',
    placeholder: '请输入',
    value: null,
    prop: 'no',
    width: '100%',
    tips:'(数字或字母)',
    disabled:false,
    rules: [
      {
        required: true,
        message: '请输入账号',
        trigger: 'blur'
      },
      {
        pattern: numberStr,
        message: '账号格式不正确',
        trigger: 'blur'
      }
    ]
  },
  {
    label: '所属机构',
    name: 'orgId',
    type: 'select',
    placeholder: '请选择',
    value: null,
    prop: 'orgId',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请选择所属机构',
        trigger: 'change',
      },
    ],
    opt:[]
  },
  {
    label: '职务',
    name: 'positionType',
    type: 'select',
    placeholder: '请选择',
    value: null,
    prop: 'positionType',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请选择职务',
        trigger: 'change',
        type:'number',
      },
    ],
    opt:[]
  },
  {
    label: '入职时间',
    name: 'entryDate',
    type: 'selectTime',
    placeholder: '请选择',
    value: null,
    prop: 'entryDate',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请选择时间',
        trigger: 'change',
        type:  'object',
      },
    ]
  },
])